@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background: #C4D4D2 0% 0% no-repeat padding-box;
  position: relative;
  padding: 86px 0 0px;
}

.filters {
  text-align: center;
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}
