@import '../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 291px;
  margin: 0 auto;
}

.filtersWrapper {
    display: grid;
    grid-gap: 72px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
}

.footer {
  display: flex;
  margin-top: 8px;
}

.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.categoryTitle{
  font: normal normal 900 44px/50px Roc Grotesk;
letter-spacing: 0px;
color: #3A3D3A;
margin: 0 0 42px;
padding: 0;
}
.routeText{
  font: normal normal normal 20px/32px Roc Grotesk;
letter-spacing: 0px;
color: #8D918D;
text-transform: capitalize;
margin-top: 0;
padding-top: 0;
}
.resetAllButton {
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.applyButton {
  composes: filterButton;
  color: #3A3D3A;

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: #3A3D3A;
  }
}
